<template>
  <div class="contain">
    <div v-html="text"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "<p>saF都费劲哦ia发窘AJFAOFHoh发你无奈 解耦HF噢if和EF好讲佛法我饿</p>",
    };
  },
};
</script>

<style>
.contain {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
</style>